import {useAppStore} from "~/store/appStore";
const appStore = useAppStore()

export default defineNuxtRouteMiddleware(async (to, from) => {
    appStore.auth.userToken = useCookie('APP_AUTH_USER_TOKEN')
    appStore.auth.dateEndUserToken = useCookie('APP_AUTH_DATE_END_USER_TOKEN')
    // Check if user is login
    if((!appStore.auth.userToken || appStore.auth.userToken === '' ) || (appStore.auth.userToken && appStore.auth.userToken !== '' && (new Date(appStore.auth.dateEndUserToken) < new Date()))) {
        return navigateTo("/login")
    }
})